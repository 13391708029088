<template>
  <div class="clue-list">
    <Title title="招商线索"></Title>
    <!-- 豆腐块 -->
    <section class="tofu van-hairline--bottom">
      <li class="tofu-item" v-for="item in clueStatusOpt" :key="item.value" @click="onTaskType(item.value)">
        <van-badge :content="item.count">
          <van-image width="24" height="24" :src="item.imgSrc" />
        </van-badge>
        <span :class="params.clueStatus == item.value && 'theme-bg'">{{
            item.label
        }}</span>
      </li>
    </section>
    <section class="search  van-hairline--bottom">
      <!-- 列表筛选项 -->
      <van-dropdown-menu class="search-time">
        <van-dropdown-item v-model="timeRange" :options="objToOpt(dateToDateOpt)" @change="onDateToDate" />
      </van-dropdown-menu>
      <!-- 审批关键词搜索 -->
      <van-search class="search-kw" v-model="params.kw" show-action placeholder="请输入搜索关键词" @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </section>
    <!-- 线索列表 -->
    <section class="main-list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <section class="block-item" v-for="item in clueList" :key="item.clueId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.tenantName }}</div>
            <div class="title-right">
              <div class="title-right theme-color" @click="linkTo(item)">
                查看
              </div>
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">联系人：</span><span>{{ item.tentUserName }}</span>
            </p>
            <p>
              <span class="info-color">联系电话：</span><span class="theme-color" @click="tel(item.tentUserMobile)">{{
                  item.tentUserMobile
              }}</span>
            </p>
            <p>
              <span class="info-color">意向园区：</span><span>{{ item.projectName }}</span>
            </p>
            <p>
              <span class="info-color">意向面积：</span><span>{{ item.requiredAreaRange || '暂无' }}</span>
            </p>
            <p>
              <span class="info-color">来源渠道：</span><span>{{ item.channelTypeName || '暂无' }}</span>
            </p>
            <p>
              <span class="info-color">是否扫码：</span><span>{{ item.isCode == 1 ? "是" : "否" }}</span>
            </p>
            <p>
              <span class="info-color">客户状态：</span><span :style="{ 'color': clueStatusColorObj[item.clueStatus] }">{{
                  clueStatusObj[item.clueStatus]
              }}</span>
            </p>
            <p>
              <span class="info-color">招商经理：</span><span>{{ item.managerUserName || '暂无' }}</span>
            </p>
          </div>
        </section>
      </van-list>
    </section>
    <Footer></Footer>
    <!-- 悬浮按钮 -->
    <van-button class="add-btn" icon="plus" type="info" round @click="linkToAdd()" />
  </div>
</template>

<script>
import Title from "@/components/title";
import Footer from "@/components/footer";
import {
  clueStatusOpt,
  dateToDateOpt,
  clueStatusObj,
  clueStatusColorObj,
} from "@/db/clue";
import {
  getCount,
  getClueList,
} from "@/api/clue/clue"
import {
  objToOpt,
  changeDate,
} from "@/utils/utils"

export default {
  props: {},
  components: {
    Title, Footer
  },
  data() {
    return {
      clueStatusOpt,
      dateToDateOpt,
      clueStatusObj,
      clueStatusColorObj,
      objToOpt,
      timeRange: "4",
      params: {
        page: 1,
        limit: 10,
        kw: "",
        dateToDate: "", //日期
        clueStatus: "", //豆腐块状态
      },
      clueList: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
    };
  },
  mounted() {
    this.changeTimeRange(4)
    this.getCount();
    this.getClueList();
  },
  methods: {
    // 【监听】拨打电话
    tel(tel) {
      window.location.href = "tel://" + tel;
    },

    // 监听日期
    changeTimeRange(val) {
      let now = new Date();
      let Y = now.getFullYear(); // 年
      let M = now.getMonth() + 1; // 月
      let D = now.getDate(); // 日
      let W = now.getDay(); //  星期
      //  昨天
      if (val == 1) {
        let yesterdayTime = new Date(now.setTime(now.getTime() - 24 * 60 * 60 * 1000));
        let yesterday = changeDate(yesterdayTime);
        this.params.dateToDate = yesterday + " ~ " + yesterday
      } else if (val == 2) {
        let nowDate = changeDate(now);
        this.params.dateToDate = nowDate + " ~ " + nowDate
      } else if (val == 3) {
        let WeekStartDate = changeDate(new Date(Y, now.getMonth(), D - W + 1))
        let WeekEndDate = changeDate(new Date(Y, now.getMonth(), D - W + 7))
        this.params.dateToDate = WeekStartDate + " ~ " + WeekEndDate
      } else if (val == 4) {
        this.params.dateToDate = changeDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " ~ " + changeDate(new Date())
      } else if (val == 5) {
        this.params.dateToDate = changeDate(new Date(new Date().getFullYear(), 0, 1)) + " ~ " + changeDate(new Date())
      } else if (val == 6) {
        this.params.dateToDate = ""
      }
    },


    // 【请求】获取客户数量
    getCount() {
      let data = this.params;
      getCount(data).then((res) => {
        if (res.success) {
          let data = res.data;
          for (let i of this.clueStatusOpt) {
            i.count = data[i.codeName]
          }
        }
      });
    },

    // 【请求】获取客户列表
    getClueList() {
      let data = this.params;
      getClueList(data).then((res) => {
        if (res.success) {
          this.loading = false;
          if (res.data) {
            this.clueList = [...this.clueList, ...res.data];
          }
          if (this.clueList.length >= res.count) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 【请求】下拉加载
    onLoad() {
      this.params.page++;
      this.getClueList();
    },

    // 状态变更重置
    reset() {
      this.clueList = [];
      this.params.page = 1;
      this.finished = false;
      this.getClueList();
    },

    // 【监听】审批类型选择
    onTaskType(value) {
      this.params.clueStatus = value;
      this.reset();
    },

    // 【监听】时间区间赛选
    onDateToDate(value) {
      this.changeTimeRange(value);
      this.params.clueStatus = "";
      this.params.kw = "";
      this.getCount();
      this.reset();
    },

    // 【监听】搜索
    onSearch() {
      this.params.clueStatus = "";
      this.getCount();
      this.reset();
    },

    // 【监听】跳转
    linkTo(item) {
      this.$router.push("/clue/clueInfo?clueId=" + item.clueId);
    },

    // 【监听】跳转
    linkToAdd() {
      this.$router.push("/clue/clueEdit");
    }

  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/theme";

.clue-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.add-btn {
  position: absolute;
  z-index: 999;
  right: .2rem;
  bottom: .7rem;
  width: .48rem;
  height: .48rem;
}

.tofu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 0.8rem;
}

.tofu-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >span {
    margin-top: 0.05rem;
    font-size: 0.14rem;
    padding: 0 0.05rem;
    border-radius: 0.1rem;
  }
}

.theme-bg {
  background: $theme-color;
  color: #fff !important;
}

// 查询
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-time {
  display: inline-block;
  width: .7rem;
}

.search-kw {
  flex: 1;
  padding-right: .1rem;
}

// 线索列表
.main-list {
  flex: 1;
  overflow: auto;
  padding: 0 .1rem;
  background: $bg-color;
}


.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  background: #fff;

  >.block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;

    .title-left {
      display: flex;
      align-items: center;

      >i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }

  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;

    >p {
      display: flex;
      align-items: center;
      padding: 0.02rem 0;
      font-size: .14rem;
    }

    .info-color {
      display: flex;
      align-items: center;

      .van-image {
        margin-right: 0.05rem;
      }
    }
  }
}
</style>

<style>
.clue-list .van-dropdown-menu__bar {
  box-shadow: none !important;
}
</style>

